<div class="row" *ngIf="!conteudoCarregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div class="row" *ngIf="conteudoCarregado">
  <div class="col">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th
            class="text-center"
            (click)="sortColun(0)"
            *ngIf="centrocusto == '' || centrocusto == '0'"
            [ngClass]="{
              sorting: ordenacao[0].estado == 2,
              sorting_desc: ordenacao[0].estado == 0,
              sorting_asc: ordenacao[0].estado == 1
            }"
          >
            Centro de Custo
          </th>
          <th
            class="text-center"
            (click)="sortColun(0)"
            [ngClass]="{
              sorting: ordenacao[0].estado == 2,
              sorting_desc: ordenacao[0].estado == 0,
              sorting_asc: ordenacao[0].estado == 1
            }"
          >
            Perfil
          </th>
          <th class="text-center">Qntd. de Veiculos com esse Perfil</th>
          <th
            class="text-center"
            (click)="sortColun(1)"
            [ngClass]="{
              sorting: ordenacao[1].estado == 2,
              sorting_desc: ordenacao[1].estado == 0,
              sorting_asc: ordenacao[1].estado == 1
            }"
          >
            Status
          </th>
          <th class="col-2">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let dados of arrDados">
          <td align="center" *ngIf="centrocusto == '' || centrocusto == '0'">
            {{ dados.cc }}
          </td>
          <td align="center">
            {{ dados.cnome }}
          </td>
          <td align="center">
            {{ dados.veiculos }}
          </td>
          <td align="center" class="text-success" *ngIf="dados.lativo == 1">
            Ativo
          </td>
          <td align="center" class="text-danger" *ngIf="dados.lativo != 1">
            Bloqueado
          </td>
          <td>
            <button
              type="button"
              class="btn btn-warning btn-sm me-1"
              *ngIf="alterarDados"
              title="Alterar Perfil"
              (click)="editarEsp(dados.idperregra)"
            >
              <i class="fa-solid fa-edit fa-fw"></i>
            </button>
            <button
              type="button"
              class="btn btn-info btn-sm me-1"
              *ngIf="!alterarDados"
              title="Visualizar Perfil"
              (click)="editarEsp(dados.idperregra)"
            >
              <i class="fa-solid fa-eye fa-fw"></i>
            </button>
            <button
              type="button"
              class="btn btn-frota btn-sm me-1"
              title="Alterar Regras Perfil"
              (click)="editarRegrasEsp(dados.idperregra, dados.idregional)"
            >
              <i class="fa-solid fa-clipboard-list-check fa-fw"></i>
            </button>
            <button
              type="button"
              *ngIf="dados.veiculos == 0 && alterarDados"
              class="btn btn-danger btn-sm"
              title="Deletar Perfil"
              (click)="excluir(dados.idperregra)"
            >
              <i class="fa-solid fa-trash fa-fw"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="row" [hidden]="!conteudoCarregado">
  <div class="col d-flex justify-content-end">
    <ngb-pagination
      [(page)]="filtro.paginaAtual"
      [pageSize]="filtro.limite"
      [collectionSize]="filtro.quantidade"
      [boundaryLinks]="true"
      [maxSize]="8"
      [rotate]="true"
      (pageChange)="paginacaoDireto($event)"
    ></ngb-pagination>
  </div>
</div>
