import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-search-select",
  templateUrl: "./search-select.component.html",
  styleUrls: ["./search-select.component.scss"],
})
export class SearchSelectComponent implements OnChanges {
  arrFiltered = [];
  groupedFiltered = [];
  @Input() multiple: boolean = false;
  @Input() arrToFilter = [];
  @Input() value: string;
  @Input() placeholder: string = "Selecione";
  @Input() ngValue: string;
  @Output() ngValueChange = new EventEmitter<string>();
  @Input() disabled: boolean;
  @Input() useGrouping?:boolean = false;

  ngOnChanges(): void {
    this.arrFiltered = this.arrToFilter;
  }

  filterMyOptions(filter: string) {
    if (!filter) {
      this.ngOnChanges();
      return;
    }

    if (this.useGrouping) {
      this.groupedFiltered = this.arrToFilter
        .map((group) => ({
          ...group,
          reports: group.reports.filter((report) =>
            report.label.toLowerCase().includes(filter.toLowerCase())
          ),
        }))
        .filter((group) => group.reports.length > 0); 
    } else {
      this.arrFiltered = this.arrToFilter.filter((element) =>
        element.label
          ? element.label.toLowerCase().includes(filter.toLowerCase())
          : String(element).toLowerCase().includes(String(filter).toLowerCase())
      );
    }
  }
}

