<mat-select
  class="form-select"
  [(ngModel)]="ngValue"
  [disabled]="disabled"
  [multiple]="multiple"
  (ngModelChange)="ngValueChange.emit(ngValue)"
  [placeholder]="placeholder"
>
  <mat-option>
    <ngx-mat-select-search
      ngModel
      (ngModelChange)="filterMyOptions($event)"
      placeholderLabel="Procurar"
      noEntriesFoundLabel="Nenhum dado encontrado"
    ></ngx-mat-select-search>
  </mat-option>
  <div *ngIf="useGrouping; else flatList">
    <ng-container *ngFor="let group of arrFiltered">
      <mat-optgroup [label]="group.name">
        <mat-option *ngFor="let option of group.reports" [value]="option.id">
          {{ option.label }}
        </mat-option>
      </mat-optgroup>
    </ng-container>
  </div>
  <ng-template #flatList>
    <mat-option 
      *ngFor="let option of arrFiltered"
      [value]="value ? option[value] : option"
    >
      {{ value ? option.label : option }}
    </mat-option>
    
  </ng-template>
</mat-select>
