import { Component, Input, ViewChild, OnInit, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TransacaoService } from "src/app/modulos/shared/services/transacao.service";
import { UserService } from "src/app/modulos/shared/services/user.service";
import { UsuariosService } from "src/app/modulos/shared/services/usuarios.service";
import Swal from "sweetalert2";

@Component({
  selector: "visualizar-transacoes-manutencao",
  templateUrl: "./visualizar-transacoes-manutencao.component.html",
})
export class VisualizarTransacoesManutencaoComponent implements OnInit {
  transacao: any = {};
  transacoesItens: any = [];
  transacoesItensTotaisProdutos: any = {};
  transacoesItensTotaisServicos: any = {};
  conteudoCarregado = false;
  altatributos = true;
  totalResult = "0,00";
  dataItem: any;
  showModalOdometro = false;

  valoresMonetarioTrans = 0;
  valoresQtdeTrans = 0;
  manutencaoAvancada: any = {};

  showModalAjustarTrans = false;

  inputAlteraTransValue: string = "";

  dataUsuario: any = { nome: "" };

  tipoUsuario = 1;
  alterarDados = true;

  @Input() id: string;
  @Input() tipo: string;
  @Input() modal = 0;

  showModalTrans = false;
  transProdutos = [];
  transServicos = [];

  totalOrcamento = [];

  modulo = "abastecimento";

  @ViewChild("transacaoImprimir") transacaoImprimir: ElementRef;

  constructor(
    private transacaoService: TransacaoService,
    private usuarioServico: UsuariosService,
    private userService: UserService,
    public router: Router,
    private route: ActivatedRoute
  ) {
    this.tipoUsuario = this.userService.tipoUsuario;
    this.alterarDados = this.userService.alterarDados;
  }

  ngOnInit() {
    this.conteudoCarregado = false;

    this.route.params.subscribe((params) => {
      if (!this.id && !this.tipo) {
        this.id = params["id"];
        this.tipo = params["tipo"];
      }

      this.carregar(this.id);
    });
  }

  checkCombustivel(item) {
    return item.idtipoproduto == 1;
  }

  carregar(id: string): void {
    this.transacaoService
      .getTransacao({ id: id, tipo: this.tipo, modulo: 2 })
      .subscribe((response) => {
        if (response.success) {
          this.manutencaoAvancada =
            response?.manutencaoAvancada ?? response.transacoesItens[0];
          this.transacao = response.transacoes;
          this.transacoesItens = response.transacoesItens;
          this.transProdutos = this.transacoesItens.filter((e) => e.ntipo == 2);
          this.transServicos = this.transacoesItens.filter((e) => e.ntipo == 1);
          this.transacao.horatrans = this.transacao.datatrans2.substr(11, 8);
          this.totalOrcamento = response.totalOrcamento;

          this.transacao.valortrans -= this.transacao.desconto;

          const arrTemp = this.transacoesItens.filter(this.checkCombustivel);
          let sum: any = 0;
          for (let i = 0; i < arrTemp.length; i++) {
            sum += parseFloat(arrTemp[i].nqtd);
          }

          const verifica: any = Number(
            parseFloat(this.transacao.tr_odometro) -
              parseFloat(this.transacao.tr_odometroAntes)
          );

          if (verifica > 0 && sum > 0) {
            this.transacao.mediakml =
              (parseFloat(this.transacao.tr_odometro) -
                parseFloat(this.transacao.tr_odometroAntes)) /
              sum;
            this.transacao.mediakml = parseFloat(
              this.transacao.mediakml
            ).toFixed(2);
            this.transacao.mediakml = this.transacao.mediakml.replace(".", ",");
            this.transacao.mediakmlGnv = response.GNV;
          }

          this.transacoesItensTotaisProdutos = {
            cnome: "TOTAIS",
            nvalor: 0,
            total: 0,
          };

          this.transacoesItensTotaisServicos = {
            cnome: "TOTAIS",
            nvalor: 0,
            total: 0,
          };

          this.transProdutos.forEach((element) => {
            this.transacoesItensTotaisProdutos.nvalor += Number(
              element.subtotalitem
            );
            this.transacoesItensTotaisProdutos.total += Number(
              element.valoritemdesconto
            );
          });

          this.transServicos.forEach((element) => {
            this.transacoesItensTotaisServicos.nvalor += Number(
              element.subtotalitem
            );
            this.transacoesItensTotaisServicos.total += Number(
              element.valoritemdesconto
            );
          });
        }

        this.conteudoCarregado = true;
      });
    if (this.tipoUsuario == 1) {
      this.usuarioServico.getAtributos().subscribe((response) => {
        if (response.success) {
          if (response.usuario.atributos[0].alttransacao == 1) {
            this.altatributos = false;
          }
          this.dataUsuario.nome = response.usuario.atributos[0].nome;
        }
      });
    }
  }

  liberarRestricao(transacao): void {
    Swal.fire({
      title: "Liberar restrição dessa transação?",
      text: "Você está prestes a autorizar o veiculo a abastecer com uma inconsistência!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, autorize!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.transacaoService.liberarRestricao(transacao);
      },
    }).then((result) => {
      if (result.value.success) {
        Swal.fire({
          title: "",
          text: "Transação autorizada com sucesso!",
          icon: "success",
          showCancelButton: false,
        }).then(() => {
          this.router.navigate(["/" + this.modulo + "/transacoes"]);
        });
      } else {
        Swal.fire(
          "",
          "Erro ao autorizar a transação! " + result.value.message,
          "error"
        );
      }
    });
  }

  descancelar(): void {
    const transacaoId = this.transacao.idtrans;

    Swal.fire({
      title: "Tem certeza que você quer descancelar essa transação?",
      text: "Você está prestes a descancelar uma transação CANCELADA!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, descancele!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.transacaoService.descancelar(transacaoId);
      },
    }).then((result) => {
      if (result.value.success) {
        Swal.fire({
          title: "",
          text: "Transação descancelada com sucesso!",
          icon: "success",
          showCancelButton: false,
        }).then(() => {
          this.router.navigate(["/" + this.modulo + "/transacoes"]);
        });
      } else {
        Swal.fire("", result.value.message, "error");
      }
    });
  }

  PrintElem(): void {
    this.Popup(this.transacaoImprimir.nativeElement.innerHTML);
  }

  Popup(data: any): boolean {
    const mywindow = window.open("", "my div", "height=400,width=600");
    mywindow.document.write(
      "<html><head><title>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Plus Frota - Registro de Transação</title>"
    );
    mywindow.document.write("</head><body >");
    mywindow.document.write(data);
    mywindow.document.write("</body></html>");

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10

    mywindow.print();
    mywindow.close();

    return true;
  }

  abrirModalAjustarItem(data: any): void {
    this.showModalTrans = true;

    this.dataItem = data;
  }

  abrirModalAjustarOdometro(): void {
    this.showModalOdometro = true;
  }

  alterarOdomentroTransacao(data: any): void {
    Swal.fire({
      title: "Ajustar Odometro?",
      text:
        "Você está prestes a autorizar o modificação do odometro! \n" +
        "Você esta ciente que este operação acarretara na mudança do odometro atual do veiculo e todo comportamento de regras e avisos pertinente ao mesmo. " +
        "Deseja Autorizar modificação?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, autorize!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.transacaoService.ajustarOdometro(data);
      },
    }).then((result) => {
      if (result.value.success) {
        Swal.fire({
          title: "",
          text: "Odometro registrado com sucesso!",
          icon: "success",
          showCancelButton: false,
        }).then(() => {
          this.carregar(result.value.id);
          this.showModalOdometro = false;
        });
      } else {
        Swal.fire(
          "Informações sobre Credenciado!",
          result.value.message,
          "error"
        );
      }
    });
  }

  calculaSubTotal(): void {
    const Subtotal = this.valoresMonetarioTrans * this.valoresQtdeTrans;
    this.totalResult = Subtotal.toFixed(2).replace(".", ",").toString();
  }

  openModalAlteraTrans() {
    const ValorLitro = this.valoresMonetarioTrans;

    if (ValorLitro < 1) {
      Swal.fire(
        "",
        "Erro ao calcular a transação! Você não pode informar um valor do litro menor que R$ 1,00.",
        "error"
      );
      return;
    }

    this.showModalAjustarTrans = true;
  }

  salvarNovoValorTrans(): void {
    const Qtde = this.valoresQtdeTrans;
    const Valor = this.valoresMonetarioTrans * this.valoresQtdeTrans;

    Swal.fire({
      title: "Você tem certeza?",
      text: "Você confirma este ajuste de transação?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim, ajuste!",
      cancelButtonText: "Não, Cancele!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const data = this.dataItem;

        data.nqtd = Qtde;
        data.nvalor = Valor.toFixed(2);
        data.codbase = btoa(this.inputAlteraTransValue);

        return this.transacaoService.ajustarTransacao(data);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.success) {
          Swal.fire({
            title: "",
            text: "Ajuste da Transação autorizada com sucesso!",
            icon: "success",
            showCancelButton: false,
          }).then(() => {
            this.totalResult = "0,00";
            this.showModalAjustarTrans = false;
            this.showModalTrans = false;
          });
        } else {
          Swal.fire(
            "",
            "Erro ao autorizar a transação! " + result.value.message,
            "error"
          );
        }
      }
    });
  }
}
