import { Component, inject, Input, OnInit } from "@angular/core";
import { UserService } from "src/app/modulos/shared/services/user.service";
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";
import { FechamentoService } from "../../../services/fechamento.service";

@Component({
  selector: "app-empresa-faturamento-fechamento",
  templateUrl: "./empresa-faturamento-fechamento.component.html",
  styleUrls: ["./empresa-faturamento-fechamento.component.scss"],
})
export class EmpresaFaturamentoFechamentoComponent implements OnInit {
  @Input() closures;
  @Input() module: number;
  tipoUsuario: number;
  usuario;

  private userService = inject(UserService);
  private fechamentoService = inject(FechamentoService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  ngOnInit(): void {
    this.tipoUsuario = this.userService.tipoUsuario;
    this.usuario = this.userService.usuario;
  }

  get isInvoiceManager() {
    return this.module === 6;
  }

  get isCourses() {
    return this.module === 9;
  }

  expandChargesByIndex(billingGroupIndex: number) {
    this.closures[billingGroupIndex].chargesCollapsed =
      !this.closures[billingGroupIndex].chargesCollapsed;
  }

  atualizarBoletoNota(idfech: number) {
    this.fechamentoService
      .atualizarBoletoNotaFech(idfech, this.module)
      .subscribe({
        next: (response) => {
          if (response.success) {
            Swal.fire("", "Links atualizados com sucesso!", "success").then(
              () => {
                this.fechamentoService.dataChangedEvent.next();
              }
            );
          } else {
            Swal.fire("Aviso!", "Erro ao atualizar os links!", "error");
          }
        },
      });
  }

  visualizarFechamento(idfech: number, tipoFech: number) {
    if (this.module == 1 || this.module == 2) {
      this.router.navigate(["visualizar", idfech, tipoFech], {
        relativeTo: this.route,
      });
      return;
    }
    this.router.navigate(["visualizar", idfech], { relativeTo: this.route });
  }
}
